import React, { useState, useRef, useEffect } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { useAuth } from './auth';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const AudioPlayer = ({ audioUrl, onReady }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (audioUrl) {
      console.log('Loading audio URL in WaveSurfer:', audioUrl);
      
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }
     
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: 'violet',
        progressColor: 'purple',
        cursorColor: 'navy',
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 1,
        height: 80,
        barGap: 3
      });

      wavesurfer.current.load(audioUrl);

      wavesurfer.current.on('ready', () => {
        console.log('WaveSurfer ready');
        const audioDuration = wavesurfer.current.getDuration();
        setDuration(audioDuration);
        if (onReady) {
          onReady(audioDuration);
        }
      });

      wavesurfer.current.on('error', (err) => {
        console.error('WaveSurfer error:', err);
      });

      return () => {
        if (wavesurfer.current) {
          wavesurfer.current.destroy();
        }
      };
    }
  }, [audioUrl, onReady]);
  

  const togglePlayPause = () => {
    if (wavesurfer.current) {
      wavesurfer.current.playPause();
      setIsPlaying(!isPlaying);
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="relative">
      <div ref={waveformRef} className="border border-purple-300 rounded p-2" />
      <div className="flex justify-between items-center mt-2">
        <button 
          onClick={togglePlayPause}
          className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
        >
          {isPlaying ? 'Pause' : 'Play'}
        </button>
        <div className="text-white">
          {formatTime(currentTime)} / {formatTime(duration)}
        </div>
      </div>
    </div>
  );
};

const App = () => {
  const { user, signIn, signOut } = useAuth();
  console.log('Current auth state:', { user, isAuthenticated: !!user }); // Debug
  const [prompt, setPrompt] = useState('');
  const [bpm, setBpm] = useState(120);
  const [key, setKey] = useState('C');
  const [scale, setScale] = useState('Major');
  const [duration, setDuration] = useState(20); // Default duration 20 seconds
  const [multiBandDiffusion, setMultiBandDiffusion] = useState(false);
  const [normalizationStrategy, setNormalizationStrategy] = useState('loudness');
  const [topK, setTopK] = useState(250);
  const [topP, setTopP] = useState(0);
  const [temperature, setTemperature] = useState(1);
  const [classifierFreeGuidance, setClassifierFreeGuidance] = useState(3);
  const [outputFormat, setOutputFormat] = useState('mp3');
  const [seed, setSeed] = useState(-1);
  const [inputAudio, setInputAudio] = useState(null);
  const [inputAudioUrl, setInputAudioUrl] = useState(null);
  const [inputAudioDuration, setInputAudioDuration] = useState(0);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [gradientColors, setGradientColors] = useState([getRandomColor(), getRandomColor()]);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [continuationStart, setContinuationStart] = useState(3); // Default continuation start
  const statusInterval = useRef(null);
  const startTime = useRef(null);

  useEffect(() => {
    return () => {
      if (statusInterval.current) {
        clearInterval(statusInterval.current);
      }
    };
  }, []);

  const gradientStyle = {
    background: `linear-gradient(135deg, ${gradientColors[0]}, ${gradientColors[1]})`,
  };

  console.log('Current auth state:', { user, isAuthenticated: !!user });

  const changeBackground = () => {
    setGradientColors([getRandomColor(), getRandomColor()]);
  };

  const handleInputAudioReady = (duration) => {
    setInputAudioDuration(duration);
    // Adjust duration if needed to stay within limits
    if (inputAudio) {
      const maxDuration = 30 - continuationStart;
      if (duration > maxDuration) {
        setDuration(maxDuration);
      }
    }
  };


  if (user === null || user === undefined) {
    console.log('No authenticated user, showing login screen');
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-4" style={gradientStyle}>
        <div className="bg-purple-900 bg-opacity-50 rounded-lg shadow-lg max-w-md w-full p-6">
          <h1 className="text-3xl font-bold mb-6 text-white text-center" 
              style={{ fontFamily: "'Orbitron', sans-serif" }}>
            Splatif.ai Generator
          </h1>
          <div className="text-white text-center mb-6">
            Please sign in to access the music generator
          </div>
          <button
            onClick={() => {
              console.log('Sign in button clicked');
              signIn();
            }}
            className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
          >
            Sign in with Google
          </button>
        </div>
      </div>
    );
  }
  console.log('User is authenticated, showing main app');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setInputAudio(file);
    if (file) {
      const url = URL.createObjectURL(file);
      setInputAudioUrl(url);
      setContinuationStart(3); // Reset continuation start to default
      // Will trigger handleInputAudioReady when audio loads
    } else {
      setInputAudioUrl(null);
      setInputAudioDuration(0);
      setDuration(20); // Reset to default duration
    }
  };

  const updateElapsedTime = () => {
    if (startTime.current) {
      const elapsed = Math.floor((Date.now() - startTime.current) / 1000);
      setElapsedTime(elapsed);
    }
  };

  const generateMusic = () => {
    setIsLoading(true);
    setError('');
    setAudioUrl(null);
    setElapsedTime(0);
    startTime.current = Date.now();
    changeBackground();
  
    if (statusInterval.current) {
      clearInterval(statusInterval.current);
    }
    statusInterval.current = setInterval(updateElapsedTime, 1000);
  
    // Create FormData instance
    const formData = new FormData();
    
    // Append all form fields
    formData.append('prompt', prompt);
    formData.append('bpm', Math.round(bpm));
    formData.append('key', key);
    formData.append('scale', scale);
    formData.append('duration', Math.round(duration));
    
    if (inputAudio) {
      formData.append('input_audio', inputAudio);
      formData.append('continuation', 'true');
      formData.append('continuation_start', Math.round(continuationStart));
      formData.append('continuation_end', 30);
    }
  
    formData.append('multi_band_diffusion', multiBandDiffusion);
    formData.append('normalization_strategy', normalizationStrategy);
    formData.append('top_k', topK);
    formData.append('top_p', topP);
    formData.append('temperature', temperature);
    formData.append('classifier_free_guidance', classifierFreeGuidance);
    formData.append('output_format', outputFormat);
    formData.append('seed', seed);
  
    console.log('Sending request...');
    fetch(`${process.env.REACT_APP_API_URL}/generate`, {
      method: 'POST',
      body: formData,
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.blob();
    })
    .then(audioBlob => {
      console.log('Received audio blob:', audioBlob.type, audioBlob.size);
      const url = URL.createObjectURL(audioBlob);
      console.log('Created URL:', url);
      setAudioUrl(url);
    })
    .catch(err => {
      console.error('Generation error:', err);
      setError(`Error generating music: ${err.message}`);
    })
    .finally(() => {
      setIsLoading(false);
      if (statusInterval.current) {
        clearInterval(statusInterval.current);
      }
      startTime.current = null;
    });
  };

  const getEstimatedTimeRemaining = () => {
    if (!isLoading) return null;
    const totalEstimate = inputAudio ? 180 : 120; // 3 minutes for continuation, 2 minutes for new generation
    const remaining = Math.max(0, totalEstimate - elapsedTime);
    return `Estimated ${remaining} seconds remaining`;
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4 relative" style={gradientStyle}>
      <button
        onClick={signOut}
        className="absolute top-4 right-4 bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded text-sm"
      >
        Sign Out
      </button>
      
      <div className="bg-purple-900 bg-opacity-50 rounded-lg shadow-lg max-w-md w-full p-6">
        <h1 className="text-3xl font-bold mb-6 text-white text-center" style={{ fontFamily: "'Orbitron', sans-serif" }}>
          Splatif.ai Generator
        </h1>
        
        <div className="space-y-4">
          <div className="mb-4">
            <label className="block text-white mb-2">Prompt:</label>
            <input
              type="text"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              className="w-full p-2 rounded bg-purple-800 bg-opacity-50 border border-purple-600 focus:border-purple-400 focus:outline-none text-white"
              placeholder="Enter prompt"
            />
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">BPM: {bpm}</label>
            <input
              type="range"
              min="50"
              max="200"
              value={bpm}
              onChange={(e) => setBpm(e.target.value)}
              className="w-full"
            />
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">Key:</label>
            <select
              value={key}
              onChange={(e) => setKey(e.target.value)}
              className="w-full p-2 rounded bg-purple-800 bg-opacity-50 border border-purple-600 focus:border-purple-400 focus:outline-none text-white"
            >
              {['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'].map((k) => (
                <option key={k} value={k}>{k}</option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">Scale:</label>
            <select
              value={scale}
              onChange={(e) => setScale(e.target.value)}
              className="w-full p-2 rounded bg-purple-800 bg-opacity-50 border border-purple-600 focus:border-purple-400 focus:outline-none text-white"
            >
              {['Major', 'Minor', 'Dorian', 'Phrygian', 'Lydian', 'Mixolydian', 'Locrian'].map((s) => (
                <option key={s} value={s}>{s}</option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">Duration (seconds):</label>
            <input
              type="number"
              value={duration}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                if (inputAudio) {
                  // For continuation, limit to 30 - continuation_start
                  const maxDur = 30 - continuationStart;
                  setDuration(Math.min(Math.max(3, value), maxDur));
                } else {
                  // For new generation, limit between 3 and 200
                  setDuration(Math.min(Math.max(3, value), 200));
                }
              }}
              className="w-full p-2 rounded bg-purple-800 bg-opacity-50 border border-purple-600 focus:border-purple-400 focus:outline-none text-white"
              min={3}
              max={inputAudio ? (30 - continuationStart) : 200}
              step={1}
            />
            {inputAudio && (
              <p className="text-sm text-gray-300 mt-1">
                Maximum duration: {Math.floor(30 - continuationStart)} seconds 
                (30s continuation limit - {continuationStart}s continuation start)
              </p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">Input Audio:</label>
            <input
              type="file"
              onChange={handleFileChange}
              accept="audio/*"
              className="w-full p-2 rounded bg-purple-800 bg-opacity-50 border border-purple-600 focus:border-purple-400 focus:outline-none text-white"
            />
          </div>

          {inputAudioUrl && (
            <div className="mb-4">
              <label className="block text-white mb-2">Input Audio Waveform:</label>
              <AudioPlayer 
                audioUrl={inputAudioUrl}
                onReady={handleInputAudioReady}
              />
            </div>
          )}

          <div className="mb-4">
            <label className="block text-white mb-2">
              <input
                type="checkbox"
                checked={multiBandDiffusion}
                onChange={(e) => setMultiBandDiffusion(e.checked)}
                className="mr-2"
              />
              Multi-band Diffusion
            </label>
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">Normalization Strategy:</label>
            <select
              value={normalizationStrategy}
              onChange={(e) => setNormalizationStrategy(e.target.value)}
              className="w-full p-2 rounded bg-purple-800 bg-opacity-50 border border-purple-600 focus:border-purple-400 focus:outline-none text-white"
            >
              {['loudness', 'clip', 'peak', 'rms'].map((s) => (
                <option key={s} value={s}>{s}</option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">Top K: {topK}</label>
            <input
              type="range"
              min="1"
              max="500"
              value={topK}
              onChange={(e) => setTopK(e.target.value)}
              className="w-full"
            />
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">Top P: {topP}</label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={topP}
              onChange={(e) => setTopP(e.target.value)}
              className="w-full"
            />
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">Temperature: {temperature}</label>
            <input
              type="range"
              min="0"
              max="2"
              step="0.01"
              value={temperature}
              onChange={(e) => setTemperature(e.target.value)}
              className="w-full"
            />
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">Classifier Free Guidance: {classifierFreeGuidance}</label>
            <input
              type="range"
              min="1"
              max="10"
              step="0.1"
              value={classifierFreeGuidance}
              onChange={(e) => setClassifierFreeGuidance(e.target.value)}
              className="w-full"
            />
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">Output Format:</label>
            <select
              value={outputFormat}
              onChange={(e) => setOutputFormat(e.target.value)}
              className="w-full p-2 rounded bg-purple-800 bg-opacity-50 border border-purple-600 focus:border-purple-400 focus:outline-none text-white"
            >
              <option value="mp3">MP3</option>
              <option value="wav">WAV</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">Seed:</label>
            <input
              type="number"
              value={seed}
              onChange={(e) => setSeed(e.target.value)}
              className="w-full p-2 rounded bg-purple-800 bg-opacity-50 border border-purple-600 focus:border-purple-400 focus:outline-none text-white"
              placeholder="Enter seed (-1 for random)"
            />
          </div>
        </div>

        <button
          onClick={generateMusic}
          disabled={isLoading}
          className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 mt-6"
        >
          {isLoading ? 'Generating...' : 'GENERATE'}
        </button>

        {isLoading && (
          <div className="mt-4 text-center">
            <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-purple-500"></div>
            <p className="mt-2 text-white">Time elapsed: {elapsedTime} seconds</p>
            <p className="mt-1 text-gray-300">{getEstimatedTimeRemaining()}</p>
            <p className="mt-2 text-sm text-gray-300">
              {inputAudio ? 
                "Continuation typically takes about 3 minutes" :
                "Generation typically takes about 2 minutes"
              }
            </p>
          </div>
        )}

        {error && (
          <div className="mt-4 bg-red-100 border border-red-400 text-red-700 px-3 py-3 rounded relative" role="alert">
            <strong className="font-bold">Error:</strong>
            <span className="block sm:inline"> {error}</span>
          </div>
        )}

        {audioUrl && (
          <div className="mt-4">
            <h2 className="text-xl font-bold mb-2 text-white">Generated Audio:</h2>
            <AudioPlayer 
              audioUrl={audioUrl}
              onReady={() => {}}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default App;